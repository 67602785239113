<template>
<report-table>
  <template #thead v-if="rows.length">
    <tr>
      <th scope="col" rowspan="2">
        <span class=" svg-icon svg-icon-secondary svg-icon-sm">
          <inline-svg src="/media/svg/icons/General/Other1.svg" />
        </span>
      </th>
      <th scope="col" rowspan="2">{{ $t('ORDER.START_DATE') }}</th>
      <template v-for="row in rows[0]">
        <th scope="col" colspan="2" :key="row.catName">
          <router-link target="_blank" class="text-secondary"
                       :to="{ name: 'servicesCategoriesEdit', params:{id:row.catUuid} }"
          >
            {{ row.catName }}
          </router-link>
          <span class=" svg-icon svg-icon-primary svg-icon-sm">
            <inline-svg src="/media/svg/icons/Navigation/Up-right.svg" />
          </span>
        </th>
      </template>
    </tr>
    <tr>
      <template v-for="(row,j) in rows[0]">
        <th :key="row.catUuid">{{ $t('ORDER.SETUP.QUANTITY') }}</th>
        <th :key="row.catUuid+j">{{ $t('ORDER.SUMMARY.COST') }}</th>
      </template>
    </tr>
  </template>
  <template #tbody v-if="rows.length">
    <tr :key="i" v-for="(row, i) in rows">
      <td>{{ i+1 }}</td>
      <template v-for="(cat,j) in row">
        <td v-if="j === 0" :key="cat.date+i">{{ dateFormat(cat.date) }}</td>
        {{ add(`count-${cat.catUuid}`,cat.count) }}
        {{ add(`cost-${cat.catUuid}`,cat.costWithDiscount) }}
        <td :key="cat.catName+i">{{ cat.count }}</td>
        <td :key="cat.catName+j+i">{{ cat.costWithDiscount/100 }}</td>
      </template>
    </tr>
    <tr class="text-primary">
      <td colspan="2">{{ $t('REPORTS.TOTAL') }}</td>
      <template v-for="(cat, k) in rows[0]">
        <td :key="cat.catUuid">{{ stored(`count-${cat.catUuid}`) }}</td>
        <td :key="cat.catUuid + k">{{ stored(`cost-${cat.catUuid}`)/100 }}</td>
      </template>
    </tr>
  </template>
  <template #tbody v-else>
    <tr>
      <td scope="col" class="text-center">{{ $t('REPORTS.NO_DATA') }}</td>
    </tr>
  </template>
</report-table>
</template>

<script>
import ReportTable from '../table/Table'
import { FORMAT_DATE } from '../../../helpers/validation'

export default {
  name: 'NumServicesByCategoryTable',
  components: { ReportTable },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      FORMAT_DATE,
      loopStorage: {},
    }
  },
  computed: {
    stored() {
      return (k) => {
        return this.loopStorage[k] || 0
      }
    },
  },
  watch: {
    rows() {
      this.resetLoopStorage()
    },
  },
  methods: {
    dateFormat(val) {
      return this.$moment(val).format(FORMAT_DATE)
    },
    add(k, v) {
      if (this.loopStorage[k] === undefined) {
        this.loopStorage[k] = 0
      }
      this.loopStorage[k] += v
    },
    get(k) {
      return this.loopStorage[k] || 0
    },
    resetLoopStorage() {
      this.loopStorage = {}
    },
  },
}
</script>

<style scoped>
</style>
